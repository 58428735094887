import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useSetActionCall } from "../../../Collections/services";

const StyledAccordion = styled(Accordion)`
  margin-bottom: 16px;
  box-shadow: none;

  &.MuiAccordion-root:before {
    display: none;
  }
`;

const ActComponent = ({ actList, currActId, sessionId }) => {
  console.log("actList", actList);
  const { mutate: variantChange } = useSetActionCall();

  // Default payload template
  const getPayload = (actId, interactionId) => ({
    session_id: sessionId,
    message: {
      type: "change_act",
      // interaction_id: interactionId ? interactionId : "",
      message: actId,
    },
  });

  const interactionPayload = (interactionId) => ({
    session_id: sessionId,
    message: {
      type: "change_interaction",
      // interaction_id: interactionId ? interactionId : "",
      message: interactionId,
    },
  });

  const [expandedAct, setExpandedAct] = useState(false);
  const [expandedInteractions, setExpandedInteractions] = useState({});

  useEffect(() => {
    if (currActId) {
      const targetActIndex = actList.findIndex(
        (act) => act.act_id === currActId
      );
      if (targetActIndex !== -1) {
        setExpandedAct(`panel${targetActIndex}`);
      }
    }
  }, [currActId, actList]);

  // Handle expansion of an act panel
  const handleActChange = (panel) => (event, isExpanded) => {
    setExpandedAct(isExpanded ? panel : false);

    if (isExpanded) {
      const actId = actList[panel.replace("panel", "")]?.act_id;
      variantChange(getPayload(actId, null));
    }
  };

  const handleInteractionChange =
    (actIndex, interactionPanel) => (event, isExpanded) => {
      setExpandedInteractions((prevState) => ({
        ...prevState,
        [actIndex]: isExpanded ? interactionPanel : false,
      }));

      if (isExpanded) {
        const interactionId = actList[actIndex]?.interactions.find(
          (interaction) =>
            interaction.interaction_id ===
            interactionPanel.replace("interaction", "")
        )?.interaction_id;

        const actId = actList[actIndex]?.act_id;
        variantChange(interactionPayload(interactionId));
      }
    };

  return (
    <Box>
      {actList?.map((act, actIndex) => (
        <StyledAccordion
          key={act.act_id}
          expanded={expandedAct === `panel${actIndex}`}
          onChange={handleActChange(`panel${actIndex}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${actIndex}bh-content`}
            id={`panel${actIndex}bh-header`}
          >
            <Box>
              <Typography
                sx={{ fontWeight: "500", fontSize: "20px", color: "#4B5563" }}
              >
                {act.act_title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#6B7280",
                  mt: 0.5,
                }}
              >
                {act.act_text}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {act.interactions.map((interaction) => (
              <StyledAccordion
                key={interaction.interaction_id}
                expanded={
                  expandedInteractions[actIndex] ===
                  `interaction${interaction.interaction_id}`
                }
                onChange={handleInteractionChange(
                  actIndex,
                  `interaction${interaction.interaction_id}`
                )}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`interaction${interaction.interaction_id}-content`}
                  id={`interaction${interaction.interaction_id}-header`}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#374151",
                    }}
                  >
                    {interaction.interaction_title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#4B5563",
                    }}
                  >
                    {interaction.interaction_text}
                  </Typography>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};

export default ActComponent;
