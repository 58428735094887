import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import styled from "styled-components";
import { Turn as Hamburger } from "hamburger-react";

const SidebarContainer = styled(Box)`
  position: fixed;
  top: 5%;
  left: 10px;
  background-color: #eeedeb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.5s ease-in-out;
  width: ${({ open }) => (open ? "48px" : "48px")};
  height: ${({ open }) => (open ? "auto" : "48px")};
  gap: 10px;
  padding-bottom: ${({ open }) => (open ? "10px" : "0px")};
`;

const IconButtonStyled = styled(IconButton)`
  transition: transform 0.3s, opacity 0.3s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? "scale(1)" : "scale(0.8)")};
`;

const HamburgerContainer = styled(Box)`
  z-index: 1100;
`;

const AnimatedMenu = ({ sessionId, sendRotateCall, controlData }) => {
  const [open, setOpen] = useState(false);

  // State to track the active status of each control
  const [controlStates, setControlStates] = useState(
    controlData?.reduce((acc, control) => {
      acc[control?.control_id] = control?.default_value === "true";
      return acc;
    }, {})
  );

  const handleToggle = (controlId) => {
    const newValue = !controlStates?.[controlId];

    const payload = {
      session_id: sessionId,
      message: {
        type: "control",
        message: {
          control_id: controlId,
          value: newValue?.toString(),
        },
      },
    };

    // Update the state and send the payload
    setControlStates((prevStates) => ({
      ...prevStates,
      [controlId]: newValue,
    }));

    sendRotateCall(payload);
  };

  return (
    <SidebarContainer open={open}>
      <HamburgerContainer>
        <Hamburger
          toggled={open}
          toggle={setOpen}
          size={20}
          direction="right"
        />
      </HamburgerContainer>

      {open &&
        controlData?.map((control) => {
          const icon = control?.control_icons?.find(
            (icon) => icon.file_type === "L"
          );

          return (
            <Box
              sx={{
                backgroundColor: controlStates?.[control?.control_id]
                  ? "white"
                  : "",
                borderRadius: "10px",
              }}
            >
              <IconButtonStyled
                key={control?.control_id}
                visible={open}
                onClick={() => handleToggle(control?.control_id)}
              >
                <img
                  src={icon?.path}
                  alt={control?.control_name}
                  style={{ width: 18, height: 18 }}
                />
              </IconButtonStyled>
            </Box>
          );
        })}
    </SidebarContainer>
  );
};

export default AnimatedMenu;
